import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {Paginator, Modal, Toast} from '../dsb.js';
import numeral from 'numeral';
import Layout from '../components/layout';
import { BeakerIcon, TrashIcon, TrophyIcon, BuildingStorefrontIcon,  PencilSquareIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

export default function Downloads() {

	const {authFetch} = useAuthRequest();

	const queryRef = useRef();

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [message, setMessage] = useState(false);

	const [photoSets, setPhotoSets] = useState(null);


	const load = async() => {
		const _searchParams = new URLSearchParams(searchParams);

		let query = '';
		let page = 1;

		if (_searchParams.has('q')) {
			query = _searchParams.get('q');
		}

		if (_searchParams.has('page')) {
			page = _searchParams.get('page');
		}

		const url = `/api/admin/photosets?page=${page}&q=${query}`;

		const response = await authFetch(url);

		if (response.photosets) {
			setPhotoSets(response.photosets);
		}
	}

	const submitForm = (e) => {
		e.preventDefault();
		navigate(`/downloads?q=${queryRef.current.value}&page=1`);
		return false;
	}

	const changePage = (page) => {
		const _searchParams = new URLSearchParams(searchParams);

		let query = '';

		if (_searchParams.has('q')) {
			query = _searchParams.get('q');
		}

		if (query.length > 0) {
			navigate(`/downloads?q=${query}&page=${page}`);
		} else {
			navigate(`/downloads?page=${page}`);
		}
	}


	useEffect(() => {

		load();
	}, [searchParams]);

	return <Layout current="downloads">
			<div>
				<div className="md:flex items-center justify-between">
					<h3 className="text-4xl font-bold my-8 text-white">Photo Downloads</h3>
					<Link to="/new-download" className="bg-indigo-600 text-white rounded px-6 py-3">Create Set</Link>
				</div>
				<div className="mb-4">
				<form onSubmit={submitForm}>
				<div className="flex shadow-lg mb-4">
				<input ref={queryRef} type="text" className="text-sm bg-white px-4 py-3 rounded-l-md w-full block" placeholder="Search by title or description"/>
				<button className="bg-indigo-600 font-bold text-sm px-6 py-3 rounded-r-md text-white fill-white" type="submit">
					<MagnifyingGlassIcon className="w-5 h-5 text-white"/>
				</button>
				</div>

				</form>
				</div>
				<div className="overflow-x-auto bg-gray-800 rounded-md mt-4 w-full">
					<table className="table-auto overflow-scroll w-full">
					<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
						<tr>
							<th className="px-4 py-3">ID</th>
							<th className="px-4 py-3">Title</th>
							<th className="px-4 py-3">Photo Count</th>
							<th className="px-4 py-3">Credits</th>
							<th className="px-4 py-3">Total Downloads</th>
							<th className="px-4 py-3">Actions</th>
						</tr>
					</thead>
					<tbody className="text-sm text-slate-200">
					{
						photoSets!=null&&photoSets.data.map(photoSet => {
							return <tr key={`photoSet-${photoSet.id}`}>
								<td className="px-4 py-3"><Link to={`/downloads/${photoSet.id}`} className="text-cyan-400">{photoSet.id}</Link></td>
								<td className="px-4 py-3"><Link to={`/downloads/${photoSet.id}`} className="text-cyan-400">{photoSet.title}</Link></td>
								<td className="px-4 py-3">{photoSet.photo_count}</td>
								<td className="px-4 py-3">{photoSet.credits}</td>
								<td className="px-4 py-3">{photoSet.user_downloads_count}</td>
								<td className="px-4 py-3">
									<Link to={`/downloads/${photoSet.id}`} className="text-cyan-400">Manage Set</Link>
								</td>
							</tr>
						})
					}
					</tbody>
					</table>
				</div>
				{
					photoSets!=null&&<div className="my-4 py-4 md:py-0">
						<Paginator data={photoSets} changePage={changePage}/>
					</div>
				}
			</div>
			<Toast message={message} onReset={() => setMessage(false)} duration={3500}/>
	</Layout>;
}