import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, OrderStatus, SetupStatus, MenuButton, Modal, Toast, Button, Card, Label, Select} from '../dsb.js';
import numeral from 'numeral';
import Layout from '../components/layout';
import { BeakerIcon, TrashIcon, BuildingStorefrontIcon,  PencilSquareIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

const links = [
	{
		id: 'dashboard',
		url: '/dashboard',
		name: 'Dashboard',
		icon: <ChartBarIcon className="w-5 h-5"/>
	},
	{
		id: 'stores',
		url: '/stores',
		name: 'Stores',
		icon: <BuildingStorefrontIcon className="w-5 h-5"/>
	},
	{
		id: 'setups',
		url: '/setups',
		name: 'Setup Requests',
		icon: <UsersIcon className="w-5 h-5"/>
	},
];

const defaultMarkups = [
	{label:'3d-mink-lashes', value: 2},
	{label:'wigs', value: 2},
	{label:'accessories-tools', value: 2},
	{label:'extensions', value: 2},
	{label:'lip-gloss', value: 2}
];

export default function Store() {

	const {authFetch} = useAuthRequest();

	const navigate = useNavigate();

	const {id} = useParams();

	/* Refs */

	const customBrandingRef = useRef();

	const noteRef = useRef();

	const exemptionRef = useRef();

	const rejectionReasonRef = useRef();

	const planRef = useRef();

	const planNote = useRef();

	const emailRef = useRef();

	const storeNameRef = useRef();

	/* state management */

	const [ordersModal, setOrdersModal] = useState(false);

	const [selectedDiscount, setSelectedDiscount] = useState(false);

	const [updateModal, setUpdateModal] = useState(false);

	const [orderNumbers, setOrderNumbers] = useState('');

	const [searchParams, setSearchParams] = useSearchParams();

	const [editBranding, setEditBranding] = useState(false);

	const [expertSetup, setExpertSetup] = useState(false);

	const [selectedTaxExemption, setSelectedTaxExemption] = useState(false);

	const [planModal, setPlanModal] = useState(false);

	const [banModal, setBanModal] = useState(false);

	const [multiplierModal, setMultiplierModal] = useState(false);

	const [multiplier, setMultiplier] = useState(2);

	const [round, setRound] = useState()

	const [discountModal, setDiscountModal] = useState(false);

	const [showRejectionReason, setShowRejectionReason] = useState('');

	const [store, setStore] = useState(null);

	const [planCode, setPlanCode] = useState('');

	const [taxExemptions, setTaxExemptions] = useState([]);

	const [multipliers, setMultipliers] = useState([]);

	const [orders, setOrders] = useState([]);

	const [webhooks, setWebhooks] = useState([]);

	const [disabled, setDisabled] = useState(false);

	const [discountAmount, setDiscountAmount] = useState(0);

	const [collectionId, setCollectionId] = useState(null);

	const [collections, setCollections] = useState([]);

	const [message, setMessage] = useState(false);

	const [showNoteForm, setShowNoteForm] = useState(false);

	const [storeRequests, setStoreRequests] = useState([]);

	const [notes, setNotes] = useState([]);

	const [discounts, setDiscounts] = useState([]);

	const [jobs, setJobs] = useState([]);

	const [markups, setMarkups] = useState(defaultMarkups);

	const [products, setProducts] = useState({imported:0, in_list: 0});
	const [revenue, setRevenue] = useState({total:0, last_30: 0});

	const loadStore = async() => {

		const response = await authFetch(`/api/admin/stores/${id}`);

		const { multipliers, store_requests, store, webhooks, orders, products, revenue, notes, tax_exemptions, collections, discounts} = response;
		

		if (store) {
			setWebhooks(webhooks);
			setOrders(orders);
			setMultipliers(multipliers);
			setProducts(products);
			setRevenue(revenue);
			setStore(store);
			setNotes(notes);
			setTaxExemptions(tax_exemptions);
			setCollections(collections);
			setDiscounts(discounts);
			setStoreRequests(store_requests);
			setPlanCode(store.plan_code);
		}

	}

	const createExpertSetup = async() => {
		const body = new FormData();

		markups.forEach(markup => {
			body.append(`markup[${markup.label}]`, markup.value);
		});


		const response = await authFetch(`/api/admin/stores/${id}/setup`, 'POST', body);

		const {message, error} = response;

		if (error) {
			setMessage(error);
		}

		if (message) {
			setMessage(message);
			loadStore();
			setExpertSetup(false);
		}
	}

	const retrieveOrders = async() => {
		const body = new FormData();
		body.append('order_numbers', orderNumbers);
		const response = await authFetch(`/api/admin/stores/${id}/orders`, 'POST', body);

		const { error, message } = response;

		if (error) {
			setMessage(error);
		} 

		if (message) {
			setMessage(message);
			loadStore();
			setOrdersModal(false);
		}
	}

	const handleExemptionStatus = (e) => {
		if (e.target.value == 'rejected') {
			setShowRejectionReason(true);
		} else {
			setShowRejectionReason(false);
		}
	}

	const repairWebhooks = async() => {

		setDisabled(true);
		const response = await authFetch(`/api/admin/stores/${id}/webhooks`, 'POST');

		const { error, results } = response;

		if (error) {
			setMessage(error);
		} 

		if (results) {
			setMessage(results);
			loadStore();
		}

		setDisabled(false);
	}

	const updateCustomBranding = async() => {
		setDisabled(true);

		if (customBrandingRef.current) {
			const formData = new FormData();

			formData.append('custom_branding_number', customBrandingRef.current.value);

			const response = await authFetch(`/api/admin/stores/${id}`, 'POST', formData);

			const { error, message } = response;

			if (error) {
				setMessage(error);
			} 

			if (message) {
				setMessage(message);
				setEditBranding(false);
				loadStore();
			}

		}

		

		setDisabled(false);
	}

	const viewTaxExemption = async() => {


		const response = await authFetch(`/api/admin/stores/${id}/tax-exemptions/${selectedTaxExemption.id}`, 'GET');

		const { error, url } = response;

		if (error) {
			setMessage(error);
		} 

		if (url) {
			window.open(url, '_blank');
		}

		
	}

	const submitNote = async() => {
		setDisabled(true);

		if (noteRef.current) {
			const formData = new FormData();

			formData.append('note_txt', noteRef.current.value);

			const response = await authFetch(`/api/admin/stores/${id}/notes`, 'POST', formData);

			const { error, message } = response;

			if (error) {
				setMessage(error);
			} 

			if (message) {
				setMessage(message);
				setShowNoteForm(false);
				loadStore();
			}

		}

		

		setDisabled(false);
	}

	const saveExemptionStatus = async() => {
		setDisabled(true);

		if (exemptionRef.current && selectedTaxExemption) {
			const formData = new FormData();

			formData.append('status', exemptionRef.current.value);

			if (exemptionRef.current.value == 'rejected' && rejectionReasonRef.current) {

				formData.append('rejection_reason_txt', rejectionReasonRef.current.value);
			}

			const response = await authFetch(`/api/admin/stores/${id}/tax-exemptions/${selectedTaxExemption.id}`, 'POST', formData);

			const { error, message } = response;

			if (error) {
				setMessage(error);
			} 

			if (message) {
				setMessage(message);
				setSelectedTaxExemption(false);
				loadStore();
			}

		}

		

		setDisabled(false);
	}

	const addDiscount = async() => {
		setDisabled(true);

		
		const formData = new FormData();

		formData.append('amount', discountAmount);
		formData.append('collection_id', collectionId);

		const response = await authFetch(`/api/admin/stores/${id}/discount`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setMessage(message);
			setDiscountModal(false);
			loadStore();
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);
	}

	const submitPlanChange = async() => {
		setDisabled(true);

		const formData = new FormData();
		
		formData.append('plan_code', planCode);

		const response = await authFetch(`/api/admin/stores/${store.id}/plan`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setMessage(message);
			setPlanModal(false);
			loadStore();
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);
	}

	const banUser = async() => {
		setDisabled(true);

		const formData = new FormData();

		if (store.banned_at != null) {
			formData.append('unban', '1');
		}

		const response = await authFetch(`/api/admin/stores/${store.id}/ban`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setMessage(message);
			setBanModal(false);
			loadStore();
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);
	}

	const repairFulfillmentService = async() => {
		const response = await authFetch(`/api/admin/stores/${id}/fulfillmentService`, 'POST');

		const {message, error} = response;

		if (message) {
			setMessage(message);
			loadStore();
		}

		if (error) {
			setMessage(error);
		}
	}

	const saveStoreDetails = async() => {

		const formData = new FormData();

		formData.append('store_name', storeNameRef.current.value);
		formData.append('email', emailRef.current.value);

		const response = await authFetch(`/api/admin/stores/${id}`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setUpdateModal(false);
			setMessage(message);
			loadStore();
		}

		if (error) {
			setMessage(error);
		}
	}

	const runMultiplier = async() => {
		const formData = new FormData();

		formData.append('multiplier', multiplier);
		formData.append('round_to', round);

		const response = await authFetch(`/api/admin/stores/${id}/multiplier`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			setMessage(message);
			setMultiplierModal(false);
			loadStore();
		}

		if (error) {
			setMessage(error);
		}
	}

	const updateMarkup = (label, value) => {
		

		const _markups = markups.map(markup => {
			if (markup.label == label) {
				markup.value = value;
			}
			return markup;
		})

		setMarkups(_markups);
	}

	const deleteDiscount = async() => {
		setDisabled(true);

		const response = await authFetch(`/api/admin/discounts/${selectedDiscount.id}/delete`, 'POST');

		const {message, error} = response;

		if (message) {
			setMessage(message);
			setSelectedDiscount(false);
			loadStore();
		}

		if (error) {
			setMessage(error);
		}

		setDisabled(false);
	}

	useEffect(() => {
		loadStore();
	}, []);

	useEffect(() => {
		if (selectedTaxExemption == false) {
			setShowRejectionReason(false);
		}
	}, [selectedTaxExemption])

	return <Layout current="stores">
			{store!=null?<>
				<div>
				<div className="md:flex justify-between items-center">
					<h3 className="font-header text-white text-2xl md:text-4xl font-bold pt-4 mb-3 truncate">{store.store_name}</h3>
					<div className="mb-4 md:mb-0">
					<MenuButton full={true} label="Actions" items={[
						{label: 'Get Missing Orders', action: () => setOrdersModal(true)},
						{label: 'Repair Fulfillment Service', action: () => repairFulfillmentService()},
						{label: 'Create Expert Setup', action: () => setExpertSetup(true)},
						{label: 'Grant App Credit', action: () => console.log('here')},
						{label: store.banned_at==null?'Ban User':'Un-ban User', action: () => setBanModal(true)},
						{label: 'Manually Change Plan', action: () => setPlanModal(true)},
						{label: 'Update Store Name / Email', action: () => setUpdateModal(true)},

					]}/>
					</div>
					
				</div>
				<div className="mb-3 space-x-3" >
				<PlanBadge plan={store.plan_code}/>
				{store.tax_exempt==1&&<span className="bg-indigo-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Tax Exempt</span>}
				{store.banned_at!=null&&<span className="bg-rose-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Banned</span>}
				{store.deleted_at!=null&&<span className="bg-red-600 text-xs font-bold text-white px-2 py-1 rounded-lg">Inactive</span>}
				</div>
				<div className="space-x-3">
					
					<a target="_blank"  href={`https://${store.shopify_domain}.myshopify.com`} className="text-cyan-300 text-xs hover:text-cyan-400">Website</a>
					<span className="text-slate-400 text-xs">&middot;</span>
					<a target="_blank" href={`https://partners.shopify.com/796744/stores/${store.id}`} className="text-cyan-300 text-xs hover:text-cyan-400">Shopify History</a>
					<span className="text-slate-400 text-xs">&middot;</span>
					<span className="text-slate-300 text-xs">Joined {moment(store.created_at).fromNow()}</span>
					<span className="text-slate-400 text-xs">&middot;</span>
					<span  className="text-slate-300 text-xs">Last Login {moment(store.last_login_at).fromNow()}</span>
					{store.deleted_at!=null&&<><span className="text-slate-400 text-xs">&middot;</span><span className="text-slate-300 text-xs">Uninstalled {moment(store.deleted_at).fromNow()}</span></>}
				</div>
				</div>
				<div className="md:grid grid-cols-10 gap-4  mt-4">
					<div className="col-span-7 space-y-4">
						<section className="md:grid grid-cols-4 gap-4 mb-4 space-y-3 md:space-y-0">
							<div className="bg-slate-800 px-4 py-3 rounded-md">
								<h3 className="text-2xl font-bold text-white">{numeral(revenue.total).format('$0,0.00')}</h3>
								<h5 className="text-gray-400 text-sm">Total Revenue</h5>
							</div>
							<div className="bg-slate-800 px-4 py-3 rounded-md">
								<h3 className="text-2xl font-bold text-white">{numeral(revenue.last_30).format('$0,0.00')}</h3>
								<h5 className="text-gray-400 text-sm">Last 30 Days</h5>
							</div>
							<div className="bg-slate-800 px-4 py-3 rounded-md">
								<h3 className="text-2xl font-bold text-white">{numeral(products.imported).format('0,0')}</h3>
								<h5 className="text-gray-400 text-sm">Products Imported</h5>
							</div>
							<div className="bg-slate-800 px-4 py-3 rounded-md">
								<h3 className="text-2xl font-bold text-white">{numeral(products.in_list).format('0,0')}</h3>
								<h5 className="text-gray-400 text-sm">In Import List</h5>
							</div>
						</section>
						
						<section className="bg-slate-800 rounded-md ">
							<div className="px-4 py-2 border-b border-slate-700 flex items-center justify-between">
							<h4 className="text-white text-lg font-bold">Recent Orders</h4>
							<Link to={`/stores/${store.id}/orders`} className="text-sm text-cyan-300">View All</Link>
							</div>
							<div className="overflow-auto">
							<table className="w-[1000px] md:w-full">
							<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
						<tr>
							<th className=" px-4 py-3">ID</th>
							<th className=" px-4 py-3">Customer</th>
							<th className=" px-4 py-3 text-right">Total Cost</th>
							<th className=" px-4 py-3 text-center">Status</th>
							<th className=" px-4 py-3 text-center">Ordered</th>
							<th className=" px-4 py-3 text-right">Action</th>
							
						</tr>
					</thead>
								<tbody className="divide-y divide-slate-700 w-full  text-sm text-gray-200">
								{
									orders.map(order => <tr className="w-full " key={`order-${order.id}`}>
											<td className="px-4 py-3"><Link className="text-cyan-300 hover:text-cyan-400" to={`/orders/${order.id}`}>{order.id}</Link></td>
											<td className=" px-4 py-3"><span>{order.first_name} {order.last_name}</span></td>
											<td className=" px-4 py-3 text-right"><span>{numeral(order.total_cost).format('$0,0.00')}</span></td>
											<td className=" px-4 py-3 text-right"><OrderStatus order={order}/></td>
											<td className=" px-4 py-3 text-right"><span>{moment(order.created_at).fromNow()}</span></td>
											<td className=" px-4 py-3 text-right"><Link  className="hover:text-cyan-400 text-cyan-300" to={`/orders/${order.id}`}>View Order</Link></td>
										</tr>)
								}
								</tbody>
							</table>
							</div>
						</section>
						<section className="bg-slate-800 rounded-md">
							<div className="px-4 py-2 border-b border-slate-700 flex items-center justify-between">
							<h4 className="text-white text-lg font-bold">Tax Exemptions</h4>
							</div>
							{taxExemptions.length>0?<table className="w-full">
								<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
								<tr>
									<th className=" px-4 py-3">ID</th>
									<th className=" px-4 py-3">Name</th>

									<th className=" px-4 py-3">Submitted</th>
									<th className=" px-4 py-3">Status</th>
									<th className=" px-4 py-3">Actions</th>
									
								</tr>
							</thead>
								<tbody className="divide-y divide-slate-700 w-full  text-sm text-gray-200">
									{
										taxExemptions.map(exemption => <tr className="w-full" key={`tax-${exemption.id}`}>
												<td className="px-4 py-3">
													<span>{exemption.id}</span>
												</td>
												<td className="px-4 py-3">
													<span className="block">{exemption.first_name} {exemption.last_name}</span>
													
												</td>
												<td className="px-4 py-3">
													<span>{moment(exemption.created_at).fromNow()}</span>
												</td>
												<td className="px-4 py-3">
													<span>{exemption.status}</span>
												</td>
												<td className="px-4 py-3">
													<Button onClick={() => setSelectedTaxExemption(exemption)} primary={false}>View Submission</Button>
												</td>
											</tr>)
									}
								</tbody>
							</table>:<div className="px-4 py-6 text-center text-sm">
								<span className="text-slate-200">No documents submitted</span>
							</div>}
						</section>
						{
							storeRequests.length>0&&<section className="bg-slate-800 rounded-md">
								<div className="px-4 py-2 border-b border-slate-700 flex items-center justify-between">
									<h4 className="text-white text-lg font-bold">Expert Setup Requests</h4>
								</div>
								<div className="overflow-auto">
								<table className="w-full">
								<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
								<tr>
									<th className=" px-4 py-3">ID</th>
									<th className=" px-4 py-3">Status</th>

									<th className=" px-4 py-3">Submitted</th>
									<th className=" px-4 py-3">Completed</th>
									<th className=" px-4 py-3">Action</th>
									
								</tr>
							</thead>
								<tbody className="divide-y divide-slate-700 w-full  text-sm text-gray-200">
									{
										storeRequests.map(storeRequest => <tr className="w-full" key={`store-request-${storeRequest.id}`}>
												<td className="px-4 py-3">
													<span>{storeRequest.id}</span>
												</td>
												<td className="px-4 py-3">
													<SetupStatus status={storeRequest.status}/>
													
												</td>
												<td className="px-4 py-3">
													<span>{moment(storeRequest.created_at).fromNow()}</span>
												</td>
												<td className="px-4 py-3">
													{storeRequest.completed_at!=null?<span>{moment(storeRequest.completed_at).fromNow()}</span>:<span>n/a</span>}
												</td>
												<td className="px-4 py-3">
													<Link className="text-cyan-300 hover:text-cyan-400" to={`/setup/${storeRequest.id}`} primary={false}>View Request</Link>
												</td>
											</tr>)
									}
								</tbody>
							</table>
							</div>
							</section>
						}

					</div>
					<div className="col-span-3 space-y-4">

					<div className="bg-slate-800 rounded-md">
							<h4 className="text-white text-lg font-bold px-4 border-b border-slate-700 py-2">Internal Notes</h4>
							<div className="px-4 py-3 border-b border-slate-700 space-y-2">
								{
									notes.length==0&&<p className="text-center text-slate-200 text-sm py-2">No notes saved.</p>
								}
								{
									notes.length>0&&notes.map(note => <div key={`note-${note.id}`}>
											<span className="font-bold text-white text-sm">{
												note.author!=null?note.author.name:'System'
											}:</span>
											<span className="text-slate-200 text-sm ml-1 inline">{note.note_txt}</span>
											<span className="block text-xs text-sm text-slate-500">
												{moment(note.created_at).fromNow()}
											</span>
										</div>)
								}
							</div>

							{
								showNoteForm==true?<div className="px-4 py-3 space-y-2">
									<textarea ref={noteRef} rows={3} placeholder="Enter notes here..." className="block w-full bg-white rounded-md px-4 py-3"/>
									<div className="grid grid-cols-2 gap-2">
									<Button disabled={disabled} onClick={() => submitNote()}>Save Note</Button>
									<Button disabled={disabled} onClick={() => setShowNoteForm(false)} primary={false}>Close</Button>
									</div>
								</div>:<div className="px-4 py-3">
									<Button disabled={disabled} onClick={() => setShowNoteForm(true)}>Add a note</Button>
								</div>
							}
						</div>
						
						<div className="bg-slate-800 rounded-md">
						<h4 className="text-white text-lg font-bold px-4 border-b border-slate-700 py-2">Webhooks</h4>
							<ul className="divide-y divide-slate-700">
								{
									webhooks.map(webhook => <li key={`webhook-${webhook.id}`} className="px-4 py-3">
											<div className="flex items-center justify-between">
												<span className="block text-slate-300 text-xs uppercase">{webhook.topic}</span>
												<small className="text-slate-400 text-right">{moment(webhook.updated_at).fromNow()}</small>
											</div>
										</li>)
								}
							</ul>
							<div className="px-2 mt-2 py-2 border-t border-slate-700">
							<button disabled={disabled} onClick={() => repairWebhooks()} className="disabled:opacity-50 hover:bg-indigo-700 bg-indigo-600 text-sm text-white text-center font-bold block w-full text-sm py-3 px-4 rounded-md">Repair Webhooks</button>
							</div>
						</div>
						<div className="bg-slate-800 rounded-md">
							<h4 className="text-white text-lg font-bold px-4 border-b border-slate-700 py-2">Custom Branding</h4>
							<div className="px-4 py-3">
								{
									editBranding==false?<div className="flex items-center justify-between">
										<div className="text-sm text-slate-300">
											{store.custom_branding_number==null?'No Number Assigned':store.custom_branding_number}
										</div>
										<div>
											<button className="text-cyan-300 hover:text-cyan-400 cursor-pointer" onClick={() => setEditBranding(true)}><PencilSquareIcon className="w-4 h-4"/></button>
										</div>
									</div>:<div>
										<div className="grid grid-cols-6">
											<input disabled={disabled}ref={customBrandingRef} type="text" className="rounded-l-md col-span-4 bg-white px-3 py-3 text-sm" defaultValue={store.custom_branding_number==null?'':store.custom_branding_number} placeholder="Enter branding number.."/>
											<button disabled={disabled} onClick={() => updateCustomBranding()} className="disabled:opacity-50 bg-indigo-600 text-white flex items-center justify-center "><CheckIcon className="w-5 h-5"/></button>
											<button className="rounded-r-md bg-indigo-800 text-white flex items-center justify-center" onClick={() => setEditBranding(false)}><XMarkIcon className="w-5 h-5"/></button>
										</div>
									</div>
								}
							</div>
						</div>
						<Card title="Discounts" padding={false} action={{ label: 'Add Discount', action: () => setDiscountModal(true)}}>
							{
								discounts.length==0?<div className="py-4 px-4 text-center">
									<p className="text-sm text-slate-200">No discounts created.</p>
								</div>:<div className="divide-slate-700 divide-y">{discounts.map(discount => <div className="px-4 py-2 flex items-center justify-between" key={`discount-${discount.id}`}>
										<div className="space-x-2"><strong className="text-slate-200 text-sm">{discount.collection.name}</strong><span className="text-lime-400 font-bold text-sm">-{numeral(discount.amount).format('$0,0.00')}</span></div>
										<div>
											<button className="text-slate-200 hover:text-indigo-600" onClick={() => setSelectedDiscount(discount)}><TrashIcon className="w-5 h-5 block"/></button>
										</div>
									</div>)}
									</div>
							}
						</Card>
						<Card title="Scripts" action={{label: 'Run Multiplier Script', action: () => setMultiplierModal(true)}}>
							{
								multipliers.map(multiplier => <div key={`multiplier-${multiplier.id}`} className="flex justify-between items-center text-sm text-slate-200">
										<div className="space-x-2">
											<span>{multiplier.multiplier}x</span>
											{multiplier.completed_at!=null&&<span className="uppercase text-xs text-lime-400 font-bold">Completed</span>}
										</div>

										<span>{moment(multiplier).fromNow()}</span>
									</div>)
							}
						</Card>
						
					</div>
				</div>
				<Modal title="Get Missing Orders" close={() => setOrdersModal(false)} primary={{label: 'Get Orders', action: () => retrieveOrders()}} secondary={{label: 'Close', action: () => setOrdersModal(false)}} open={ordersModal}>
					<div>
						<p className="text-slate-200 mb-4">Use this function to retrieve orders that are missing from Dropship Beauty.</p>
						<label className="text-sm font-bold text-slate-200 block">Order numbers</label>
						<p className="text-slate-400 mb-2">For multiple order numbers, separate numbers by commas.</p>
						<textarea className="bg-white text-gray-800 rounded-md block w-full px-4 py-3" placeholder="1001,1002,1003" rows="3" value={orderNumbers} onChange={(e) => setOrderNumbers(e.target.value)}></textarea>
					</div>
				</Modal>
			</>:<>

			</>}
			{
				store&&<Modal title="Create an Expert Setup" close={() => setExpertSetup(false)} primary={{label: 'Create Setup', action: () => createExpertSetup()}} secondary={{label: 'Close', action: () => setExpertSetup(false)}} open={expertSetup}>
					<div className="space-y-4">
					{
						markups.map(markup => <div key={`multiplier-${markup.label}`}>
								<label className="uppercase text-sm text-slate-400 font-bold" for={markup.label}>{markup.label.replace('-', ' ')}: <span className="font-bold text-white">{markup.value}x</span></label>
								<input id="minmax-range" type="range" step="0.1" min="1.5" max="4" value={markup.value} class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" onChange={(e) => updateMarkup(markup.label, e.target.value)}/>
							</div>)
					}
					</div>
				</Modal>
			}
			{
				store&&<Modal title="Add / Update Discount" close={() => setDiscountModal(false)} primary={{label: 'Add Discount', action: () => addDiscount() }} secondary={{label: 'Close', action: () => setDiscountModal(false)}} open={discountModal}>
					<div className="mb-4">
						<Label>Collection</Label>
						<Select options={collections.map(collection => ({label: collection.name, value:collection.id}) )} value={collectionId} onSelect={(e) => setCollectionId(e.target.value)}/>
					</div>
					<div className="mb-2">
						<Label>Discount Amount ($)</Label>
						<input type="number" className="bg-white px-4 py-3 rounded-md block w-full" placeholder="0.00" value={discountAmount} onChange={(e) => setDiscountAmount(e.target.value)}/>
					</div>
				</Modal>
			}
			{
				selectedDiscount&&<Modal title="Are you sure?" close={() => setSelectedDiscount(false)} open={(selectedDiscount!=false)} primary={{label: 'Delete', action: () => deleteDiscount()}} secondary={{label: 'Close', action: () => setSelectedDiscount(false)}}>
					<p className="text-slate-200">Delete discount settings for collection <strong>{selectedDiscount.collection.name}</strong>?</p>	
				</Modal>
			}
			{
				banModal&&<Modal title="Are you sure?" close={() => setSelectedDiscount(false)} open={(banModal)} primary={{label: store.banned_at==null?'Confirm Ban':'Unban User', action: () => banUser()}} secondary={{label: 'Close', action: () => setBanModal(false)}}>
					{store.banned_at==null?<><p className="text-slate-200">Ban user <strong>{store.store_name}</strong>?</p><p className="text-slate-200">They will no longer be able to access the app or process orders.</p></>:<>
						<p className="text-slate-200">Un-ban user <strong>{store.store_name}</strong>?</p><p className="text-slate-200">They will be able to access the app and process orders again.</p>
					</>}
				</Modal>
			}
			{
				store&&<Modal title="Manually Update Plan?" close={() => setPlanModal(false)} open={planModal} primary={{label: 'Update Plan', action: () => submitPlanChange()}} secondary={{label: 'Close', action: () => setPlanModal(false)}}>
					<div className="space-y-2">
						<div>
							<label className="text-sm font-bold text-slate-200 block">Plan</label>
							<Select options={[
									{label: 'None', value: 'none'},
									{label: 'All Access', value: 'pro'},
								]} value={planCode} onSelect={(e) => setPlanCode(e.target.value)} className="w-full"/>
						</div>
						<div>
							<label className="text-sm font-bold text-slate-200 block">Add Internal Note</label>
							<textarea className="bg-white w-full block px-3 py-2 rounded-md" rows={3} placeholder="Notes go here..."  ref={planNote}/>

						</div>
					</div>
				</Modal>
			}

			{store!=null&&<Modal title="Update Store Details" close={() => setUpdateModal(false)} open={updateModal} primary={{label: 'Save Changes', action: () => saveStoreDetails()}} secondary={{label: 'Close', action: () => setUpdateModal(false)}}>
				<div className="mb-2">
					<label className="font-bold text-sm text-white mb-2">Store Name</label>
					<input type="text" className="border px-4 py-3 bg-slate-700 border border-slate-600 text-white rounded block w-full block" ref={storeNameRef} placeholder="" defaultValue={store.store_name}/>
				</div>
				<div className="">
					<label className="font-bold text-sm text-white mb-2">Account Email</label>
					<input type="text" className="border px-4 py-3 bg-slate-700 border border-slate-600 text-white rounded block w-full block" ref={emailRef} placeholder="" defaultValue={store.email}/>
				</div>
			</Modal>}

			{
				store&&<Modal title="Run Multiplier Script" close={() => setMultiplierModal(false)} open={multiplierModal} primary={{label: 'Run Multiplier', action: () => runMultiplier(), disabled: disabled}} secondary={{label: 'Close', action: () => setMultiplierModal(false)}}>
					<label className="text-sm font-bold text-slate-200 block">Multiplier</label>
					<input type="number" className="mb-4 bg-white px-4 py-3 rounded-md block w-full" placeholder="0" value={multiplier} onChange={(e) => setMultiplier(e.target.value)}/>

					<label className="text-sm font-bold text-slate-200 block">Round Price</label>
					<Select options={[
							{label: 'Do not round', value: ''},
							{label: 'Round to 99 cents', value: '99'},
							{label: 'Round to dollar', value: '00'},
						]} value={round} onSelect={(e) => setRound(e.target.value)} className="w-full block"/>
				</Modal>
			}

			{
				store&&<Modal title="Tax Exemption" close={() => setSelectedTaxExemption(false)} primary={{label: 'Save Changes', action: () => saveExemptionStatus(), disabled: disabled}} secondary={{label: 'Close', action: () => setSelectedTaxExemption(false)}} open={selectedTaxExemption!=false}>
					<div className=""><div className="border-b border-slate-700 my-2 py-2">
						<p className="text-slate-200 mb-4">You can view {selectedTaxExemption.first_name}'s document submission by <button onClick={() => viewTaxExemption()} className="text-cyan-400">clicking here</button>.</p>
						<div className="grid grid-cols-2">
							<div>
								<label className="text-sm font-bold text-slate-200 block">Full Name</label>
								<p className="text-slate-400 mb-2">{selectedTaxExemption.first_name} {selectedTaxExemption.last_name}</p>
							</div>
							<div>
								<label className="text-sm font-bold text-slate-200 block">Legal Entity Name</label>
								<p className="text-slate-400 mb-2">{selectedTaxExemption.legal_business_name}</p>
							</div>
						</div>
						<div className="grid grid-cols-2">
							<div>
						<label className="text-sm font-bold text-slate-200 block">Email</label>
						<a href={`mailto:${store.email}`} className="block text-cyan-400 mb-2">{store.email}</a>
						</div>
						<div>
							<label className="text-sm font-bold text-slate-200 block">Tax ID</label>
						<p className="text-slate-400 mb-2">{selectedTaxExemption.tax_id}</p>
						</div>
						</div>
						<label className="text-sm font-bold text-slate-200 block">Address</label>
						<p className="text-slate-400">{selectedTaxExemption.address_1} {selectedTaxExemption.address_2!=null&&selectedTaxExemption.address_2}</p>
						<p className="text-slate-400 mb-2">{selectedTaxExemption.city} {selectedTaxExemption.state} {selectedTaxExemption.zip}</p>
						
						<label className="text-sm font-bold text-slate-200 block">Type of Business</label>
						<p className="block text-slate-400 mb-2">{selectedTaxExemption.type_of_business}</p>
					</div>
					<div>
						<label className="text-sm font-bold text-slate-200 block">Exemption Status</label>
						{selectedTaxExemption&&<select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ref={exemptionRef} defaultValue={selectedTaxExemption.status} onChange={handleExemptionStatus}>
							<option value="pending">Pending</option>
					          <option value="rejected">Rejected</option>
					          <option value="approved">Approved</option>
						</select>}
						{
							showRejectionReason&&<div className="mt-2">
							<label className="text-sm font-bold text-slate-200 block">Rejection Reason *</label>
							<textarea className="bg-white w-full block px-3 py-2 rounded-md" rows={3} placeholder="Enter rejection reason here..." defaultValue={selectedTaxExemption.rejection_reason_txt} ref={rejectionReasonRef}/>
							</div>
						}
					</div></div>
				</Modal>
			}
			<Toast message={message} onReset={() => setMessage(false)} duration={3500}/>
	</Layout>;
}